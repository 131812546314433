import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MovieCardContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  padding: 10px;
  background-color: #202020;
  border-radius: 5px;
  text-decoration: none;
`;

const Poster = styled.img`
  width: 140px;
  height: 210px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const CardTitle = styled.h3`
  text-align: center;
  margin: 5px 0;
  font-size: 1rem;
  padding-top: 0.5rem;
  height: 3em;
  overflow: hidden;
  font-family: "Merriweather Sans", sans-serif;
`;

const WatchedDate = styled.p`
  text-align: center;
  margin: 5px 0;
  font-size: 0.7rem;
  color: #cccccc;
`;

const MovieCard = ({ movie }) => {
  const formatDate = (watchDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(watchDate).toLocaleDateString(undefined, options);
  };

  const imageSrc = movie.poster_path
    ? `https://image.tmdb.org/t/p/w500/${movie.poster_path}`
    : "/img/generic_vhs.jpg";

  return (
    <MovieCardContainer to={`/movie/${movie.id}`} state={{ movie }}>
      <Poster src={imageSrc} alt={movie.title} />
      <CardTitle>{movie.title}</CardTitle>
      <WatchedDate>
        Watched on:
        <br /> {formatDate(movie.watched)}
      </WatchedDate>
    </MovieCardContainer>
  );
};

export default MovieCard;
