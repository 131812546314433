import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../hooks/useAuthContext";

const ChatList = styled.div`
  margin-top: 2rem;
`;

const ChatCard = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #fff; /* Ensure a white background */
  color: #000; /* Ensure the text is black */
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const ChatManagement = () => {
  const { user } = useAuthContext();
  const [messages, setMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/chat/recent`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        setMessages(json);
      }
    };

    fetchMessages();
  }, [user.token]);

  const filteredMessages = messages.filter(
    (msg) =>
      msg.user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      msg.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const messagesToDisplay = searchQuery
    ? filteredMessages
    : messages.slice(-14);

  return (
    <div>
      <h2>Chat Management</h2>
      <SearchBox
        type="text"
        placeholder="Search messages..."
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <ChatList>
        {messagesToDisplay.map((msg) => (
          <ChatCard key={msg._id}>
            <p>
              {msg.user.username}: {msg.text}
            </p>
            {/* Add buttons for delete or other functionalities */}
          </ChatCard>
        ))}
      </ChatList>
    </div>
  );
};

export default ChatManagement;
