// LayoutManager.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PersistentVideoPlayer from "./PersistentVideoPlayer";
import Chat from "./Chat";
import { useVideo } from "../context/VideoContext";

const LayoutManager = () => {
  const location = useLocation();
  const { setIsPiP } = useVideo();
  const isStreamPage = location.pathname === "/";

  useEffect(() => {
    setIsPiP(!isStreamPage);
  }, [location, setIsPiP, isStreamPage]);

  return (
    <div
      className={`layoutContainer ${
        isStreamPage ? "splitLayout" : "fullWidthLayout"
      }`}
    >
      <PersistentVideoPlayer />

      {isStreamPage && <Chat />}
    </div>
  );
};

export default LayoutManager;
