import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMoviesContext } from "../hooks/useMoviesContext";
import { useAuthContext } from "../hooks/useAuthContext";

const Container = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 1) 90%
      ),
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 90%);
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
  top: -200px;
  z-index: 3;
`;

const Poster = styled.img`
  width: 60%;
`;

const CommentsSection = styled.div`
  margin-top: 100px;
`;

const BackButton = styled.button`
  padding: 10px 20px;
  background-color: #d6a41e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const DrinkingGameList = styled.ol`
  margin-left: 20px;
`;

const MovieDetail = () => {
  const { dispatch } = useMoviesContext();
  const location = useLocation();
  const navigate = useNavigate();
  const movie = location.state.movie;
  const { user } = useAuthContext();

  const goBack = () => {
    navigate(-1);
  };

  const formatDate = (watchDate) => {
    if (!watchDate) return "";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(watchDate).toLocaleDateString(undefined, options);
  };

  const defaultPoster = "/img/generic_vhs.jpg";
  const defaultBackdrop = "/img/generic_backdrop.png";

  const formatDrinkingGame = (gameRules) => {
    if (!gameRules) return null;

    const rulesArray = gameRules.split(/\d+\.\s/).filter(Boolean);
    return (
      <DrinkingGameList>
        {rulesArray.map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </DrinkingGameList>
    );
  };

  const handleDeleteClick = async () => {
    if (!user) {
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/movies/` + movie._id,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE WORKOUT", payload: json });
    }
  };

  return (
    <Container>
      <Backdrop
        style={{
          backgroundImage: `url(${
            movie.backdrop
              ? `https://image.tmdb.org/t/p/original${movie.backdrop}`
              : defaultBackdrop
          })`,
        }}
      />
      <Content>
        <div>
          <Poster
            src={
              movie.poster_path
                ? `https://image.tmdb.org/t/p/original${movie.poster_path}`
                : defaultPoster
            }
            alt={movie.title}
          />
        </div>
        <div>
          <h1>
            {movie.title}{" "}
            {movie.release_date
              ? `(${new Date(movie.release_date).getFullYear()})`
              : ""}
          </h1>
          <h3>
            {formatDate(movie.watched)
              ? `Watched ${formatDate(movie.watched)} for ${movie.host}`
              : ""}
          </h3>
          <p>{movie.overview}</p>
          <h3>{movie.game ? `Drinking Game:` : ""}</h3>
          {movie.game && formatDrinkingGame(movie.game)}
          <BackButton onClick={goBack}>Go Back</BackButton>
        </div>
        <div>{/* Placeholder for future user-specific content */}</div>
      </Content>
      <CommentsSection>
        {/* Placeholder for future comments section */}
      </CommentsSection>
    </Container>
  );
};

export default MovieDetail;
