import { useVideo } from "../context/VideoContext";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const PlayerContainer = styled.div`
  ${(props) =>
    props.isPiP
      ? css`
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 300px;
          height: 170px;
          z-index: 1000;
        `
      : css`
          width: 100%;
          height: 90vh;
          overflow: hidden;
        `}
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const PersistentVideoPlayer = () => {
  const { videoUrl, isPiP, setVideoUrl } = useVideo();
  const [isLive, setIsLive] = useState(true);

  useEffect(() => {
    const checkStreamStatus = () => {
      const streamStatusElement = document.querySelector(
        ".vid-card_live.__ended"
      );
      if (streamStatusElement) {
        setIsLive(false);
        setVideoUrl("https://path/to/your/alternative/content"); // Placeholder URL
      }
    };

    checkStreamStatus();

    const intervalId = setInterval(checkStreamStatus, 60000);

    return () => clearInterval(intervalId);
  }, [setVideoUrl]);

  return (
    <PlayerContainer isPiP={isPiP} id="indexContainer">
      {isLive ? (
        <VideoFrame
          title="Persistent Video Player"
          src={videoUrl}
          allow="autoplay; encrypted-media; fullscreen;"
          frameBorder="0"
        ></VideoFrame>
      ) : (
        <div>Stream Offline - Check back later!</div>
      )}
    </PlayerContainer>
  );
};

export default PersistentVideoPlayer;
