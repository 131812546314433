import React, { useState, useEffect } from "react";
import { useMoviesContext } from "../hooks/useMoviesContext";
import MovieCard from "../components/MovieCard";
import styled from "styled-components";

const Log = () => {
  const { movies, dispatch } = useMoviesContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedHost, setSelectedHost] = useState("All Movies");

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const standardHosts = [
    "Bad Movie Night",
    "Double Feature Friday",
    "Shitflicks",
    "Unexceptional Tuesday",
    "Unexceptional Saturday",
  ];

  const fetchTMDBData = async (title) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
          title
        )}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const movieData = data.results[0];
        return {
          title: movieData.title,
          backdrop: movieData.backdrop_path,
          poster_path: movieData.poster_path
            ? `https://image.tmdb.org/t/p/original${movieData.poster_path}`
            : "img/generic_vhs.jpg",
          release_date: movieData.release_date,
          genre: movieData.genre_ids,
          rating: movieData.vote_average,
          overview: movieData.overview,
          language: movieData.language,
          id: movieData.id,
          genre_ids: movieData.genre_ids,
        };
      }
    } catch (error) {
      console.error("Error fetching TMDB data:", error);
    }
    return {};
  };

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/movies`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const moviesFromDB = await response.json();

        const moviesWithTMDB = await Promise.all(
          moviesFromDB.map(async (movie) => {
            const tmdbData = await fetchTMDBData(movie.title);
            return { ...movie, ...tmdbData };
          })
        );

        dispatch({ type: "SET_MOVIES", payload: moviesWithTMDB });
      } catch (error) {
        console.error("Error fetching movie(s):", error);
      }
    };
    fetchMovies();
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [
      <YearButton
        key="all"
        onClick={() => setSelectedYear("all")}
        selected={"all" === selectedYear}
      >
        All Years
      </YearButton>,
    ];
    for (let year = currentYear; year >= 2022; year--) {
      years.push(
        <YearButton
          key={year}
          onClick={() => setSelectedYear(year)}
          selected={year === selectedYear}
        >
          {year}
        </YearButton>
      );
    }
    return years;
  };

  const renderHostOptions = () => {
    const hosts = ["All Movies", ...standardHosts];
    return hosts.map((host) => (
      <HostButton
        key={host}
        onClick={() => setSelectedHost(host)}
        selected={selectedHost === host}
      >
        {host}
      </HostButton>
    ));
  };

  const filteredMovies = movies
    .filter((movie) => {
      const movieDate = new Date(movie.watched);
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      return (
        (selectedYear === "all" || movieDate.getFullYear() === selectedYear) &&
        movieDate <= yesterday
      );
    })
    .filter((movie) => {
      const matchesTitle = movie.title.toLowerCase().includes(searchQuery);
      return selectedHost === "All Movies"
        ? matchesTitle
        : matchesTitle && movie.host === selectedHost;
    })
    .sort((a, b) => new Date(b.watched) - new Date(a.watched));

  return (
    <Container>
      <Content>
        <Title>Watch Log</Title>
        <Description>
          Explore our collection of movies we've watched. Search for any movie
          to see details like reviews, drinking games, themes, and other
          interesting information. Click on a movie for more details!
        </Description>
        <div>
          <HostSelection>{renderHostOptions()}</HostSelection>
          <YearSelection>{renderYearOptions()}</YearSelection>
          <SearchBox
            type="text"
            placeholder="Search movies..."
            onChange={handleSearchChange}
          />
        </div>
        <MovieGrid>
          {filteredMovies?.length > 0 ? (
            filteredMovies.map((movie) => (
              <MovieCard key={movie._id} movie={movie} />
            ))
          ) : (
            <NoMoviesFound>No movies found.</NoMoviesFound>
          )}
        </MovieGrid>
      </Content>
    </Container>
  );
};

export default Log;

const Container = styled.div`
  overflow: hidden; /* Prevents scrolling */
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
  max-width: 800px;
  margin: 20px auto; /* Adjust top and bottom margin */
  color: #fff; /* A neutral, readable color */
  font-size: 1rem; /* Adjust size as necessary */
  line-height: 1.5; /* For better readability */
`;

const HostSelection = styled.div`
  height: 30px; /* Fixed height */
  margin-bottom: 20px; /* Consistent margin */
  text-align: center;
`;

const YearSelection = styled.div`
  height: 30px; /* Fixed height */
  margin-bottom: 20px; /* Consistent margin */
  text-align: center;
`;

const SearchBox = styled.input`
  width: 600px;
  height: 40px;
  margin: 20px auto; /* Center the search box and add some vertical space */
  display: block; /* Ensure the element is a block-level element */
  text-align: center; /* Center text in the input field */
  border-radius: 10px;

  &::placeholder {
    text-align: center; /* Center placeholder text */
    opacity: 1; /* Optional: Adjust the opacity as needed */
  }
`;

const MovieGrid = styled.div`
  width: 50%; /* Set a fixed width for the movie grid */
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columns for larger screens */
  grid-gap: 10px;
  justify-items: center;
  min-height: 500px;

  @media screen and (max-width: 1265px) {
    width: 90%;
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 833px) {
    grid-template-columns: repeat(1, 1fr);

    img {
      width: 100%;
    }
  }
`;

const YearButton = styled.button`
  margin: 0 10px; /* Space between buttons */
  padding: 5px 10px;
  background-color: #777; /* Example blue background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: #ffcc00; /* Choose your desired background color */
    color: #000; /* Choose your desired text color */
    font-weight: bold;
  `}
`;

const HostButton = styled(YearButton)`
  ${({ selected }) =>
    selected &&
    `
    background-color: #ffcc00; /* Choose your desired background color */
    color: #000; /* Choose your desired text color */
    font-weight: bold;
  `}
`;

const NoMoviesFound = styled.p`
  font-size: 1.2rem;
  color: #777; /* Choose your desired text color */
  width: 500px;
`;
