import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import Header from "./components/Header";
import Stream from "./pages/Stream";
import Log from "./pages/Log";
import Schedule from "./pages/Schedule";
import MovieDetail from "./components/MovieDetail";
import Login from "./pages/Login";
import Shows from "./pages/Shows";
import Signup from "./pages/Signup";
import FAQ from "./pages/FAQ";
import Merch from "./pages/Merch";
import UserProfile from "./components/UserProfile";
import "./App.css";
import LayoutManager from "./components/LayoutManager";
import Admin from "./pages/Admin";

const App = () => {
  const { user } = useAuthContext();

  return (
    <div className="container">
      <BrowserRouter>
        <Header />
        <LayoutManager />
        <Routes>
          <Route path="/" element={<Stream />} />
          <Route path="/log" element={<Log />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/merch" element={<Merch />} />
          {user && user.isAdmin && <Route path="/admin" element={<Admin />} />}
          <Route path="/movie/:id" element={<MovieDetail />} />
          <Route
            path="/login"
            element={!user ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/signup"
            element={!user ? <Signup /> : <Navigate to="/" />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/profile/:userId" element={<UserProfile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
