import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMoviesContext } from "../hooks/useMoviesContext";
import ShowCard from "../components/ShowCard";

const initialShowsData = [
  {
    name: "Shitflicks",
    host: "Shitflicks",
    graphic: "/img/host-shit-flicks.png",
    description:
      "Wednesdays are for Shit Flicks, the midweek mayhem hosted by @shitflicks from the UK, kicking off at 9PM GMT. Prepare to traverse the globe of groan-worthy cinema with some of the most outrageously fun and bizarre bad movies you've ever seen. Each week features a unique theme that promises to deliver the weird, the wild, and the wonderfully wretched. Buckle up for a ride through the best of the worst that cinema has to offer!",
    schedule: "Wednesdays at 8PM GMT",
  },
  {
    name: "Bad Movie Night",
    host: "RegularFrankieFan",
    graphic: "/img/host-bad-movie-night.png",
    description:
      "Sundays are the titular Bad Movie Night hosted by @RegularFrankieFan . Starting at 9:00 PM EST at badmovienight.tv, this is the show you've been waiting for. It's not just about watching bad movies, it's about experiencing them. Get ready for lots of interactive elements including text and voice chat, curated pre-show, contests and giveaways for best riffs, drinking game, trivia and much more!",
    schedule: "Sundays at 9PM EST",
  },
];

const Shows = () => {
  const [shows] = useState(initialShowsData);
  const { movies, dispatch } = useMoviesContext();

  const fetchTMDBData = async (title) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
          title
        )}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const movieData = data.results[0];
        return {
          title: movieData.title,
          backdrop: movieData.backdrop_path,
          poster_path: movieData.poster_path
            ? `https://image.tmdb.org/t/p/original${movieData.poster_path}`
            : "img/generic_vhs.jpg",
          release_date: movieData.release_date,
          genre: movieData.genre_ids,
          rating: movieData.vote_average,
          overview: movieData.overview,
          language: movieData.language,
          id: movieData.id,
          genre_ids: movieData.genre_ids,
        };
      }
    } catch (error) {
      console.error("Error fetching TMDB data:", error);
    }
    return {};
  };

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/movies`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const moviesFromDB = await response.json();

        // Enhance each movie with additional data from TMDb
        const moviesWithTMDB = await Promise.all(
          moviesFromDB.map(async (movie) => {
            const tmdbData = await fetchTMDBData(movie.title);

            return { ...movie, ...tmdbData };
          })
        );

        dispatch({ type: "SET_MOVIES", payload: moviesWithTMDB });
      } catch (error) {
        console.error("Error fetching movie(s):", error);
      }
    };
    fetchMovies();
  }, [dispatch]);

  return (
    <ShowsContainer>
      <Title>Our Shows</Title>
      {shows.map((show, index) => (
        <ShowItem key={index} show={show} movies={movies} />
      ))}
    </ShowsContainer>
  );
};

const ShowItem = ({ show, movies }) => {
  const today = new Date();
  const todayDateString = today.toISOString().split("T")[0]; // Gets the date part only in 'YYYY-MM-DD' format

  // Filter upcoming movies for this show
  const upcomingMovies = movies
    .filter((movie) => {
      const watchedDateString = new Date(movie.watched)
        .toISOString()
        .split("T")[0]; // Convert watched date to 'YYYY-MM-DD' string
      return watchedDateString > todayDateString && movie.host === show.name;
    })
    .sort((a, b) => new Date(a.watched) - new Date(b.watched))
    .slice(0, 2); // Limit to the next 2 movies

  // Filter recently shown movies for this show, sorted by watched date descending
  const recentMovies = movies
    .filter(
      (movie) => new Date(movie.watched) < today && movie.host === show.name
    )
    .sort((a, b) => new Date(b.watched) - new Date(a.watched))
    .slice(0, 2); // Limit to the last 2 movies

  return (
    <ShowItemContainer>
      <ShowGraphic src={show.graphic} alt={show.name} />
      <h3>{show.name}</h3>
      <p>Hosted by: {show.host}</p>
      <p>{show.description}</p>
      <p>Schedule: {show.schedule}</p>
      <MovieListContainer>
        <UpcomingContainer>
          <h4>Upcoming:</h4>
          <MovieGrid>
            {upcomingMovies.map((movie) => (
              <ShowCard key={movie.id} movie={movie} />
            ))}
          </MovieGrid>
        </UpcomingContainer>
        <RecentContainer>
          <h4>Recently Shown:</h4>
          <MovieGrid>
            {recentMovies.map((movie) => (
              <ShowCard key={movie.id} movie={movie} />
            ))}
          </MovieGrid>
        </RecentContainer>
      </MovieListContainer>

      <BackCatalogueLink to="/log">View Full Back Catalogue</BackCatalogueLink>
    </ShowItemContainer>
  );
};

export default Shows;

const ShowsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const ShowItemContainer = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background-color: #202020; /* Example background color */
  border-radius: 25px; /* Example border radius */
  text-decoration: none; /* Remove underline from links */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const ShowGraphic = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const MovieListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const UpcomingContainer = styled.div``;

const RecentContainer = styled.div``;

const MovieGrid = styled.div`
  display: flex;
  justify-content: center;
`;

const BackCatalogueLink = styled(Link)`
  display: block;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
