import React from "react";
import styled from "styled-components";

const faqData = {
  General: [
    {
      question: "Is Bad Movie Night free?",
      answer:
        "Yes! We do have a Patreon with exclusive content and events, but most of our screenings are still free. All Patreon proceeds go directly to the Bad Movie Night community!",
    },
    {
      question: "How can I connect with other bad movie enthusiasts?",
      answer:
        "Our Discord server is a great place to chat with fellow fans. We also hold regular community events and discussions.",
    },
    {
      question:
        "Are there any age restrictions for participating in watch-alongs?",
      answer:
        "Due to the nature of some films, participants should be 18 years or older. Viewer discretion is advised.",
    },
    {
      question: "How can I suggest a movie for a watch-along?",
      answer:
        "We love hearing your suggestions! You can submit movie suggestions in our Discord server.",
    },
    {
      question: "Are there any community guidelines for watch-alongs?",
      answer:
        "Yes, we encourage respectful and fun interactions. Please avoid spoilers, offensive comments, and disruptive behavior. Our full community guidelines can be found on our website.",
    },
  ],
  Patreon: [
    {
      question: "What do I get by supporting Bad Movie Night on Patreon?",
      answer:
        "By supporting us on Patreon, you get exclusive access to events, content, and merchandise, including custom-designed stickers and pins from our Bad Movie Night Swag Club...",
    },
    {
      question: "Can I host a watch-along?",
      answer:
        "Hosting opportunities are available for our Patreon supporters. If you're interested, please contact us on Discord.",
    },
  ],
  WatchAlongs: [
    {
      question: "When are watch-alongs?",
      answer: "Check the schedule for the full list of shows and screenings.",
    },
    {
      question: "Do I need a subscription service to watch along?",
      answer:
        "All watch-alongs are streamed on https://badmovienight.tv/, and no streaming service is required.",
    },
    {
      question: "Where can I see what movies have already been screened?",
      answer:
        "Check out our log to see what cinematic gems we've unearthed so far.",
    },
    {
      question: "Are the movies streamed in high definition?",
      answer:
        "We strive to provide the best viewing experience possible. Because most of the movies screened are not exactly on the Criterion list for a 4k restoration, quality may vary. It's part of the charm of a bad movie watch!",
    },
  ],
  Account: [
    {
      question: "What are the benefits of creating an account?",
      answer:
        "Signing up for an account is completely free and unlocks several features including participating in our live chat, accessing exclusive content, and being a part of the Bad Movie Night community.",
    },
    {
      question: "Is my personal information safe?",
      answer:
        "We value your privacy and take necessary measures to protect your personal information. Please refer to our Privacy Policy for detailed information on how we handle user data.",
    },
    {
      question: "I'm having issues with my account. What should I do?",
      answer:
        "If you encounter any issues with your account, please contact our support team through our Discord server or email us at support@badmovienight.tv for assistance.",
    },
  ],
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <FAQItemContainer>
      <FAQQuestion onClick={toggleIsOpen}>{question}</FAQQuestion>
      {isOpen && (
        <FAQAnswer>
          <p>{answer}</p>
        </FAQAnswer>
      )}
    </FAQItemContainer>
  );
};

const FAQCategory = ({ title, items }) => (
  <div>
    <FAQCategoryTitle>{title}</FAQCategoryTitle>
    {items.map((item, index) => (
      <FAQItem key={index} question={item.question} answer={item.answer} />
    ))}
  </div>
);

const FAQ = () => {
  return (
    <FAQContainer>
      <FAQPageTitle>Frequently Asked Questions</FAQPageTitle>
      {Object.entries(faqData).map(([category, items], index) => (
        <FAQCategory key={index} title={category} items={items} />
      ))}
      <SupportMessage>
        <p>
          If you didn't find the answer to your question, feel free to ask in
          our{" "}
          <a
            href="https://discord.gg/SmpZ7WeuFy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord server
          </a>
          . One of our mods will be happy to assist you!
        </p>
      </SupportMessage>
    </FAQContainer>
  );
};

export default FAQ;

const FAQContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  width: 80%; /* Adjust width as needed */
  max-width: 800px; /* Maximum width */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const FAQItemContainer = styled.div`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px 0;
`;

const FAQQuestion = styled.button`
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #d8a10e; /* Adjust the color */
  transition: color 0.3s;

  &:hover {
    color: #b58e06; /* Darker shade of yellow for hover effect */
  }
`;

const FAQAnswer = styled.div`
  padding: 0 10px;
  margin-top: 10px;
`;

const SupportMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  font-style: italic;
`;

const FAQCategoryTitle = styled.h2`
  color: #d7a51f; /* Title color */
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 1.5rem;
`;

const FAQPageTitle = styled.h1`
  text-align: center;
`;
