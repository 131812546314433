import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { FaDiscord, FaInstagram } from "react-icons/fa";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #202020;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 50px;
  margin-right: 1rem;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;
`;

const NavButton = styled(Link)`
  color: #d7a51f;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  background-color: #333;
  &:hover {
    background-color: #444;
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const TextLink = styled(Link)`
  color: #d7a51f;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LogoutText = styled.span`
  color: #d32f2f;
  cursor: pointer;
  font-size: 0.875rem; /* Smaller font size */
  &:hover {
    text-decoration: underline;
  }
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #d7a51f;
  &:hover {
    color: #ad7e08;
  }
`;

const Header = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const handleClick = () => {
    logout();
  };

  return (
    <HeaderWrapper>
      <LogoLink to="/">
        <Logo src="/img/logo.png" alt="Bad Movie Night Logo" />
      </LogoLink>
      <NavLinks>
        <NavButton to="/">TUNE IN</NavButton>
        <NavButton to="/schedule">SCHEDULE</NavButton>
        <NavButton to="/shows">SHOWS</NavButton>
        <NavButton to="/log">WATCH LOG</NavButton>
        <NavButton to="/faq">FAQ</NavButton>
        <NavButton
          as="a"
          href="https://www.patreon.com/badmovienighttv"
          target="_blank"
          rel="noopener noreferrer"
        >
          PATREON
        </NavButton>
        <IconLink
          href="https://discord.gg/SmpZ7WeuFy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaDiscord />
        </IconLink>
        <IconLink
          href="https://www.instagram.com/badmovienighttv"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </IconLink>
      </NavLinks>
      <UserSection>
        {user ? (
          <>
            {user.isAdmin && <NavButton to="/admin">Admin Panel</NavButton>}
            <ProfileImage
              src={user.profilePicture}
              alt={`${user.username}'s Profile`}
            />
            <TextLink to={`/profile/${user._id}`}>{user.username}</TextLink>
            <LogoutText onClick={handleClick}>Logout</LogoutText>
          </>
        ) : (
          <div>
            <NavButton to="/login">Login</NavButton>
            <NavButton to="/signup">Signup</NavButton>
          </div>
        )}
      </UserSection>
    </HeaderWrapper>
  );
};

export default Header;
