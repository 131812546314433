import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AdminMovieCard from "./AdminMovieCard";

const MovieManagement = () => {
  const [movies, setMovies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newMovie, setNewMovie] = useState({
    title: "",
    watched: "",
    host: "",
  });

  const [hostOptions, setHostOptions] = useState([]);
  const [tmdbOptions, setTmdbOptions] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/movies`
        );
        const data = await response.json();
        const moviesWithPosters = await Promise.all(
          data.map(async (movie) => {
            if (!movie.poster_path) {
              const tmdbData = await fetchTMDBData(movie.title);
              return { ...movie, poster_path: tmdbData.poster_path };
            }
            return movie;
          })
        );
        setMovies(moviesWithPosters);
      } catch (error) {
        console.error("Error fetching movies:", error);
      }
    };

    const fetchHosts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/hosts`
        );
        const data = await response.json();
        setHostOptions(data);
      } catch (error) {
        console.error("Error fetching hosts:", error);
      }
    };

    fetchMovies();
    fetchHosts();
  }, []);

  const fetchTMDBData = async (title) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
          title
        )}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        return data.results.map((movie) => ({
          id: movie.id,
          title: movie.title,
          year: movie.release_date.split("-")[0],
          poster_path: movie.poster_path
            ? `https://image.tmdb.org/t/p/original${movie.poster_path}`
            : "img/generic_vhs.jpg",
        }));
      }
    } catch (error) {
      console.error("Error fetching TMDB data:", error);
    }
    return [];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMovie({ ...newMovie, [name]: value });

    if (name === "title") {
      setSearchQuery(value);
      fetchTMDBData(value).then((options) => setTmdbOptions(options));
    }
  };

  const handleMovieSelect = (movie) => {
    setSelectedMovie(movie);
    setNewMovie({ ...newMovie, title: `${movie.title} (${movie.year})` });
    setTmdbOptions([]);
  };

  const handleAddMovie = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/movies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newMovie),
        }
      );
      const data = await response.json();
      setMovies([...movies, data]);
      setNewMovie({
        title: "",
        watched: "",
        host: "",
      });
      setSelectedMovie(null);
    } catch (error) {
      console.error("Error adding movie:", error);
    }
  };

  const handleDeleteMovie = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/movies/${id}`, {
        method: "DELETE",
      });
      setMovies(movies.filter((movie) => movie._id !== id));
    } catch (error) {
      console.error("Error deleting movie:", error);
    }
  };

  const filteredMovies = movies.filter((movie) =>
    movie.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const moviesToDisplay = searchQuery ? filteredMovies : movies.slice(-14);

  return (
    <Container>
      <Section>
        <SectionTitle>Add a New Movie</SectionTitle>
        <SectionDescription>
          Fill in the details below to add a new movie to the collection. If the
          date is in the future, it will appear in the schedule. If it is in the
          past, it will be added to the watch log. Once a scheduled movie is
          screened, it automatically moves to the watch log.
        </SectionDescription>
        <Form onSubmit={handleAddMovie}>
          <Input
            type="text"
            name="title"
            placeholder="Title"
            value={newMovie.title}
            onChange={handleInputChange}
            required
          />
          {tmdbOptions.length > 0 && (
            <Dropdown>
              {tmdbOptions.map((option) => (
                <DropdownItem
                  key={option.id}
                  onClick={() => handleMovieSelect(option)}
                >
                  {option.title} ({option.year})
                </DropdownItem>
              ))}
            </Dropdown>
          )}
          <Input
            type="date"
            name="watched"
            placeholder="Watch Date"
            value={newMovie.watched}
            onChange={handleInputChange}
            required
          />
          <Select
            name="host"
            value={newMovie.host}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>
              Select a host
            </option>
            {hostOptions.map((host) => (
              <option key={host._id} value={host.showName}>
                {host.showName} - {host.hostName}
              </option>
            ))}
          </Select>
          <Button type="submit">Add Movie</Button>
        </Form>
      </Section>

      <Section>
        <SectionTitle>Edit/Delete Movies</SectionTitle>
        <SectionDescription>
          Search for a movie to edit or delete it from the watch log.
        </SectionDescription>
        <SearchBox
          type="text"
          placeholder="Search movies..."
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <MovieGrid>
          {moviesToDisplay.map((movie) => (
            <AdminMovieCard
              key={movie._id}
              movie={movie}
              onDelete={() => handleDeleteMovie(movie._id)}
              onEdit={() => console.log("Edit functionality here")}
            />
          ))}
        </MovieGrid>
      </Section>
    </Container>
  );
};

export default MovieManagement;

const Container = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #d71f20;
`;

const SectionDescription = styled.p`
  margin-bottom: 20px;
  color: #666;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #d71f20;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #b71c1c;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const MovieGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
`;

const Dropdown = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
