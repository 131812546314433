import { useVideo } from "../context/VideoContext";
import { useEffect } from "react";

export default function Stream() {
  const { setVideoUrl, setIsPiP } = useVideo();

  useEffect(() => {
    setVideoUrl("//ok.ru/videoembed/6430870085130");
    setIsPiP(false);
  }, [setVideoUrl, setIsPiP]);

  return <div></div>;
}
