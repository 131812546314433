import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import styled from "styled-components";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  margin: 0 auto;
  padding: 20px;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

const ProfileName = styled.h2`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const FavoriteMovies = styled.div`
  margin-top: 20px;
`;

const SectionHeader = styled.h3`
  margin-bottom: 10px;
  font-size: 2rem;
  text-align: center;
`;

const MovieGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  text-align: center;
`;

const MovieGridItem = styled.div``;

const MovieImage = styled.img`
  width: 7rem;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const MovieTitle = styled.p`
  margin-top: 8px;
  color: #666;
  font-size: 0.9rem;
`;

const UserProfile = () => {
  const { userId } = useParams();
  const { user, dispatch } = useAuthContext();
  const [profileData, setProfileData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setProfileData(data);
      } else {
        console.error("Error fetching profile data:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", selectedFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/upload/${userId}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        dispatch({ type: "UPDATE_USER", payload: data });
        localStorage.setItem("user", JSON.stringify({ ...user, ...data }));
        fetchUserProfile();
      } else {
        console.error("Upload failed", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <ProfileContainer>
      <ProfileImage
        src={profileData.profilePicture || "/path/to/default/image.jpg"}
        alt={`${profileData.username}'s Profile`}
      />
      <form onSubmit={handleFileUpload}>
        <input type="file" name="profilePicture" onChange={handleFileChange} />
        <button type="submit">Upload Profile Picture</button>
      </form>
      <ProfileName>{profileData.username}</ProfileName>
      {profileData.isAdmin && <p>This user is an admin</p>}
      <p>Member since: {formatDate(profileData.createdAt)}</p>
      <p>Points: {profileData.points}</p>
      <FavoriteMovies>
        <SectionHeader>Favorite Movies</SectionHeader>
        <MovieGrid>
          {profileData.favoriteMovies?.map((movie) => (
            <MovieGridItem key={movie.id}>
              <MovieImage src={movie.posterUrl} alt={movie.title} />
              <MovieTitle>{movie.title}</MovieTitle>
            </MovieGridItem>
          ))}
        </MovieGrid>
      </FavoriteMovies>
      <FavoriteMovies>
        <SectionHeader>Recently Watched Movies</SectionHeader>
        <MovieGrid>
          {profileData.recentlyWatchedMovies?.map((movie) => (
            <MovieGridItem key={movie.id}>
              <MovieImage src={movie.posterUrl} alt={movie.title} />
              <MovieTitle>{movie.title}</MovieTitle>
            </MovieGridItem>
          ))}
        </MovieGrid>
      </FavoriteMovies>
    </ProfileContainer>
  );
};

export default UserProfile;
