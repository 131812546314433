import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MoviesContextProvider } from "./context/MoviesContext";
import { AuthContextProvider } from "./context/AuthContext";
import { VideoProvider } from "./context/VideoContext";
import './process'; 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <MoviesContextProvider>
        <VideoProvider>
          <App />
        </VideoProvider>
      </MoviesContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
