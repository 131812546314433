// VideoContext.js
import React, { createContext, useState, useContext } from "react";

const VideoContext = createContext();

export const useVideo = () => useContext(VideoContext);

export const VideoProvider = ({ children }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [isPiP, setIsPiP] = useState(false); // New state for PiP mode

  const value = {
    videoUrl,
    setVideoUrl,
    isPiP,
    setIsPiP, // Add this to the context value
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};
