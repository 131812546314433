import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../hooks/useAuthContext";

const UserList = styled.div`
  margin-top: 2rem;
`;

const UserCard = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #444;
  border-radius: 5px;
`;

const UserManagement = () => {
  const { user } = useAuthContext();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        setUsers(json);
      }
    };

    fetchUsers();
  }, [user.token]);

  return (
    <div>
      <h2>User Management</h2>
      <UserList>
        {users.map((u) => (
          <UserCard key={u._id}>
            <p>
              {u.username} - {u.email}
            </p>
            {/* Add buttons for edit, delete, and other functionalities */}
          </UserCard>
        ))}
      </UserList>
    </div>
  );
};

export default UserManagement;
