import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MovieCard = styled.div`
  img {
    width: 140px;
    height: 210px;
    object-fit: cover;
    border-radius: 5px;
    padding: 0.5rem;
  }
`;

const ShowCard = ({ movie }) => {
  const formatDate = (watchDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(watchDate).toLocaleDateString(undefined, options);
  };

  const imageSrc = movie.poster_path
    ? `https://image.tmdb.org/t/p/w500/${movie.poster_path}`
    : "/img/generic_vhs.jpg";

  return (
    <div>
      <Link to={`/movie/${movie.id}`} state={{ movie }}>
        <MovieCard>
          <img className="poster" src={imageSrc} alt={movie.title} />
        </MovieCard>
      </Link>
    </div>
  );
};

export default ShowCard;
