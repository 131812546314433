import React from "react";
import styled from "styled-components";

const AdminMovieCard = ({ movie, onDelete, onEdit }) => {
  return (
    <Card>
      <Poster
        src={movie.poster_path || "img/generic_vhs.jpg"}
        alt={movie.title}
      />
      <Title>{movie.title}</Title>
      <Info>
        <Button onClick={onEdit}>Edit</Button>
        <Button onClick={onDelete}>Delete</Button>
      </Info>
    </Card>
  );
};

export default AdminMovieCard;

const Card = styled.div`
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80px;
  height: 150px; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
`;

const Poster = styled.img`
  width: 60px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
`;

const Title = styled.p`
  font-size: 0.6rem;
  color: black; /* Ensure the text is black */
  margin: 5px 0;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  width: 100%; /* Ensure the title spans the width of the card */
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const Button = styled.button`
  padding: 3px 5px;
  background-color: #d71f20;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.6rem;

  &:hover {
    background-color: #b71c1c;
  }
`;
