import React, { useState } from "react";
import styled from "styled-components";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer>
      <AccordionTitle onClick={toggleAccordion}>
        {title}
        <AccordionToggle>{isOpen ? "-" : "+"}</AccordionToggle>
      </AccordionTitle>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </AccordionContainer>
  );
};

export default Accordion;

const AccordionContainer = styled.div`
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
`;

const AccordionTitle = styled.div`
  background-color: #d71f20;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AccordionToggle = styled.span`
  font-size: 1.5rem;
`;

const AccordionContent = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 0 0 10px 10px;
`;
