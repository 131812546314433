import { useState } from "react";
import styled from "styled-components";
import { useLogin } from "../hooks/useLogin";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  return (
    <LoginForm onSubmit={handleSubmit}>
      <Title>Login</Title>
      <Label>Email:</Label>
      <Input
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <Label>Password:</Label>
      <Input
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <Button disabled={isLoading}>Log In</Button>
      {error && <Error>{error}</Error>}
    </LoginForm>
  );
};

export default Login;

const LoginForm = styled.form`
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #d71f20;
`;

const Title = styled.h3`
  text-align: center;
  color: #d6a41e;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #fff;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #d6a41e;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dfd7c1;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Error = styled.div`
  color: #ff0000;
  text-align: center;
  margin-top: 10px;
`;
