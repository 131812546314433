import React from "react";
import styled from "styled-components";
import UserManagement from "../components/UserManagement";
import MovieManagement from "../components/MovieManagement";
import ChatManagement from "../components/ChatManagement";
import Accordion from "../components/Accordion";

const Admin = () => {
  return (
    <AdminContainer>
      <Title>Admin Panel</Title>
      <Description>
        Welcome to the admin panel. Here you can manage the application.
      </Description>
      <Accordion title="User Management">
        <UserManagement />
      </Accordion>
      <Accordion title="Movie Management">
        <MovieManagement />
      </Accordion>
      <Accordion title="Chat Management">
        <ChatManagement />
      </Accordion>
    </AdminContainer>
  );
};

export default Admin;

const AdminContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #d71f20;
  margin-bottom: 20px;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.2rem;
  color: #333;
`;
