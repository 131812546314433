import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  align-items: start;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #202020;
  text-decoration: none;
`;

const Poster = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Details = styled.div`
  padding: 15px;
`;

const Trailer = styled.div`
  display: grid;
  align-items: space-around;
  justify-content: space-around;

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    border: none;
  }
`;

const Date = styled.h3`
  font-size: 1.25rem;
  font-family: "Merriweather Sans", sans-serif;
  color: #d7a51f;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1.7rem;
  font-family: "Merriweather Sans", sans-serif;
  color: white;
`;

const Subtitle = styled.p`
  font-size: 0.75rem;
  color: #d7a51f;
`;

const UpcomingMovieCard = ({ movie }) => {
  const formatDate = (watchDate) => {
    const [year, month, day] = watchDate
      .split("-")
      .map((num) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate());

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (watchTime) => {
    const formattedTime =
      watchTime.slice(0, -6) + " " + watchTime.slice(-2) + " EST";
    return formattedTime;
  };

  const letterboxdSearchUrl = (title) => {
    return `https://letterboxd.com/search/${encodeURIComponent(title)}`;
  };

  const imageSrc = movie.poster_path
    ? `https://image.tmdb.org/t/p/w500/${movie.poster_path}`
    : "/img/generic_vhs.jpg";

  return (
    <Card>
      <Poster>
        <img src={imageSrc} alt={movie.title} />
      </Poster>
      <Details>
        <Date>
          {formatDate(movie.watched)} at {formatTime(movie.time)}
        </Date>
        <Title>{movie.title}</Title>
        <Subtitle>
          <strong>Host:</strong> {movie.host}{" "}
          {movie.theme && (
            <span>
              | <strong>Theme: </strong>
              {movie.theme}
            </span>
          )}
        </Subtitle>
        {movie.overview && <p>{movie.overview}</p>}
      </Details>
      <Trailer>
        <iframe
          src={movie.trailer}
          title="Trailer"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <p>Director: {movie.director}</p>
        <a
          href={letterboxdSearchUrl(movie.title)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Letterboxd
        </a>
      </Trailer>
    </Card>
  );
};

export default UpcomingMovieCard;
