import { useVideo } from "../context/VideoContext";
import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../context/AuthContext";
import socketIOClient from "socket.io-client";
import giphy from "giphy-js-sdk-core";
import { Link } from "react-router-dom";
import Peer from "simple-peer";
import { FiRotateCcw } from "react-icons/fi";

const ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
  background-color: black;
`;

const Messages = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  max-height: calc(100vh - 60px);
  font-size: 0.8rem;
`;

const Message = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
`;

const ProfilePic = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

const MessageBubbleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageBubble = styled.div`
  padding: 10px;
  border-radius: 15px;
  width: auto;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 10px #d4a61f;
  }
`;

const Username = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
  display: block;
  color: #d4a61f;
`;

const Timestamp = styled.div`
  font-size: 0.6rem;
  text-align: right;
  color: #d4a61f;
`;

const ChatInputContainer = styled.div`
  position: relative;
  display: flex;
`;

const ChatInput = styled.textarea`
  background-color: #020202;
  color: white;
  flex-grow: 1;
  margin-right: 1rem;
  resize: none;
  border: none;
  outline: none;
  padding: 0.25rem;
`;

const GifSearchIcon = styled.span`
  font-size: 1.5rem;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isGifMenuOpen ? "#f00" : "#ff0")};
  }
`;

const GifSearchInput = styled.input`
  display: ${(props) => (props.isGifSearchOpen ? "block" : "none")};
`;

const GifSearchResults = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 5px;
  margin-top: 5px;
`;

const GifImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
`;

const LoginPrompt = styled.div`
  padding: 10px;
  background-color: #d8a31e;
  text-align: center;

  a {
    color: #d71f20;
    text-decoration: none;
  }
`;

const OnlineUsers = styled.div`
  font-size: 0.75rem;
  padding: 0.5rem 0;
  text-align: center;
`;

const Error = styled.div`
  color: #ff0000;
  margin-top: 10px;
  text-align: center;
`;

const Chat = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const { user, isAdmin } = useContext(AuthContext);
  const { isPiP } = useVideo();
  const socketRef = useRef();
  const messagesEndRef = useRef(null);
  const peersRef = useRef([]);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [gifSearchTerm, setGifSearchTerm] = useState("");
  const [gifSearchResults, setGifSearchResults] = useState([]);
  const [isGifSearchOpen, setIsGifSearchOpen] = useState(false);
  const [isGifMenuOpen, setIsGifMenuOpen] = useState(false);
  const [isGifListOpen, setIsGifListOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openMessageId, setOpenMessageId] = useState(null);
  const [activeOptionsId, setActiveOptionsId] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);

  const selfColors = [
    "#345B63",
    "#4E6E5D",
    "#4A5759",
    "#4B5C56",
    "#406E79",
    "#567D73",
    "#487A77",
    "#5A6672",
    "#536872",
    "#4D625E",
    "#556D7A",
    "#495D6E",
    "#3D7078",
    "#417D85",
    "#3C7580",
    "#2F5D5E",
    "#286053",
    "#2E4E5E",
    "#395664",
    "#304E55",
  ];

  const hostColors = [
    "#DAA49A",
    "#9A8C98",
    "#462255",
    "#5F0F40",
    "#7A6F9B",
    "#807D8C",
    "#796A8F",
    "#6B598E",
    "#758691",
    "#6F7788",
    "#807496",
    "#8A6F88",
    "#946A7D",
    "#9E6572",
    "#A86067",
    "#B15B5C",
    "#BB5651",
    "#C55146",
    "#CF4C3B",
    "#D94730",
  ];

  const patronColors = [
    "#588B8B",
    "#4E526D",
    "#6A4C93",
    "#33658A",
    "#486A6C",
    "#667D73",
    "#605A4C",
    "#5F676D",
    "#5D7A77",
    "#4E6258",
    "#547A8B",
    "#4A687D",
    "#40656F",
    "#356261",
    "#2B5F53",
    "#215C45",
    "#1A5937",
    "#135629",
    "#0C531B",
    "#05500D",
  ];

  const otherColors = [
    "#50514F",
    "#2E5266",
    "#3C415C",
    "#2D3047",
    "#4A5058",
    "#536872",
    "#4D625E",
    "#5A6872",
    "#556D7A",
    "#495D6E",
    "#424E5A",
    "#3B4F51",
    "#345046",
    "#2D513C",
    "#265232",
    "#1E5328",
    "#17541E",
    "#0F5514",
    "#08560A",
    "#035700",
  ];

  function getColorForUser(user) {
    let colors;
    if (user.isSelf) {
      colors = selfColors;
    } else if (user.isAdmin) {
      colors = hostColors;
    } else if (user.isPatreonSubscriber) {
      colors = patronColors;
    } else {
      colors = otherColors;
    }

    const index =
      [...user.username].reduce((sum, char) => sum + char.charCodeAt(0), 0) %
      colors.length;
    return colors[index];
  }

  function getLabelForUser(user) {
    if (user.isSelf) {
      return "you";
    } else if (user.isAdmin) {
      return "host";
    } else if (user.isPatreonSubscriber) {
      return "patron";
    } else {
      return "";
    }
  }

  const gClient = giphy(process.env.REACT_APP_GIPHY_API_KEY);

  useEffect(() => {
    const fetchGifs = async () => {
      try {
        const response = await gClient.search("gifs", {
          q: gifSearchTerm,
          limit: 9,
        });
        setGifSearchResults(
          response.data.map((gif) => gif.images.fixed_width.url)
        );
      } catch (err) {
        console.error("Error fetching gifs:", err);
      }
    };

    if (gifSearchTerm) {
      fetchGifs();
    } else {
      setGifSearchResults([]);
    }
  }, [gifSearchTerm]);

  const displayAndChooseGif = async (gifUrls) => {
    let gifChoice = window.prompt(
      "Choose a gif by entering its number:\n" +
        gifUrls.map((url, index) => `${index + 1}: ${url}`).join("\n")
    );
    gifChoice = parseInt(gifChoice, 10);
    if (isNaN(gifChoice) || gifChoice < 1 || gifChoice > gifUrls.length) {
      window.alert(
        "Invalid choice. Please enter a number between 1 and " + gifUrls.length
      );
      return await displayAndChooseGif(gifUrls);
    }
    return gifUrls[gifChoice - 1];
  };

  function handleGifSelect(gif) {
    setInput(gif.images.downsized_medium.url);
  }

  const getOrCreatePeer = (user) => {
    let peerObj = peersRef.current.find((p) => p.user === user);
    if (!peerObj) {
      const newPeerObj = {
        user,
        peer: new Peer({ initiator: false, trickle: false }),
      };
      peersRef.current = [...peersRef.current, newPeerObj];
      peerObj = newPeerObj;
    }
    return peerObj;
  };

  useEffect(() => {
    socketRef.current = socketIOClient(ENDPOINT);
    socketRef.current.on("video chat signal", (data) => {
      const peerObj = getOrCreatePeer(data.user);
      peerObj.peer.signal(data.signalData);
      peerObj.peer.on("stream", (stream) => {});
    });

    socketRef.current.on("online users count", (count) => {
      setOnlineUsers(count);
    });

    socketRef.current.on("chat message", (message) => {
      setMessages((prevMessages) => {
        message.userProfilePicture =
          message.userProfilePicture || "/img/blank_profile_picture.jpg";

        return [...prevMessages, message];
      });
    });

    return () => socketRef.current.disconnect();
  }, []);

  const sendMessage = async () => {
    if (user) {
      let messageData;
      let messageText = input;

      if (replyingTo) {
        messageText = `Replying to @${replyingTo.user.username}: ${replyingTo.text}\n\n${input}`;
      }

      if (messageText.startsWith("/giphy ")) {
        const query = messageText.replace("/giphy ", "");
        try {
          const response = await gClient.search("gifs", { q: query, limit: 1 });
          const gifUrl = response.data[0].images.fixed_width.url;
          messageData = {
            text: "",
            imageUrl: gifUrl,
            user: user._id,
            username: user.username,
            userProfilePicture: user.profilePicture,
          };
        } catch (err) {
          console.error("Error fetching gif:", err);
          return;
        }
      } else {
        messageData = {
          text: messageText,
          user: user._id,
          username: user.username,
          userProfilePicture: user.profilePicture,
        };
      }

      socketRef.current.emit("chat message", messageData);
      setInput("");
      setReplyingTo(null);
    }
  };

  useEffect(() => {
    const fetchRecentMessages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/chat/recent`
        );
        if (!response.ok) throw new Error("Failed to fetch");

        const data = await response.json();
        setMessages(
          data.map((msg) => ({
            ...msg,
            userProfilePicture: msg.user
              ? msg.user.profilePicture
              : "/img/blank_profile_picture.jpg",
            username: msg.user ? msg.user.username : "Anonymous",
          }))
        );
      } catch (err) {
        console.error("Error fetching recent messages:", err);
      }
    };

    fetchRecentMessages();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      const messagesContainer = messagesEndRef.current;
      const isScrolledToBottom =
        messagesContainer.scrollHeight - messagesContainer.clientHeight <=
        messagesContainer.scrollTop + 1;

      if (isScrolledToBottom) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [messages]);

  if (isPiP) {
    return null;
  }

  function handleReply(event, msg) {
    event.stopPropagation();
    setInput(`Quoting "${msg.text}": `);
    setActiveOptionsId(null);
  }

  function handleEdit() {
    setActiveOptionsId(null);
  }

  function handleDelete() {
    setActiveOptionsId(null);
  }

  return (
    <ChatContainer>
      <OnlineUsers>
        {onlineUsers} Poor {onlineUsers === 1 ? "Soul" : "Souls"} Watching
      </OnlineUsers>
      <Messages>
        {messages.map((msg, index) => {
          const paragraphs = msg.text.split("\n\n");
          const isUserMessage = msg.user && user && msg.user._id === user._id;
          const isGiphyLink = msg.text.includes("giphy.com/");
          const isImageLink = msg.text.match(/\.(jpeg|jpg|gif|png)$/) != null;
          const isLink =
            msg.text.startsWith("http://") || msg.text.startsWith("https://");

          return (
            <Message key={msg._id}>
              <ProfilePic
                src={msg.userProfilePicture || "/img/blank_profile_picture.jpg"}
                alt={msg.user ? msg.user.username : "Anonymous"}
              />
              <MessageBubbleContainer>
                <MessageBubble
                  style={{ backgroundColor: getColorForUser(msg.user || {}) }}
                  onClick={() => console.log(msg.text)}
                >
                  <Username>
                    {msg.user ? msg.user.username : "Anonymous"}
                  </Username>
                  {isGiphyLink || isImageLink ? (
                    <GifImage src={msg.text} alt="Image" />
                  ) : isLink ? (
                    <a
                      href={msg.text}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {msg.text}
                    </a>
                  ) : (
                    <span>{msg.text}</span>
                  )}
                  <Timestamp>
                    {new Date(msg.createdAt).toLocaleTimeString()}
                  </Timestamp>
                  <FiRotateCcw onClick={() => setReplyingTo(msg)} />
                </MessageBubble>
                {activeOptionsId === index && (
                  <div>
                    <button onClick={(event) => handleReply(event, msg)}>
                      Reply
                    </button>
                    {msg.user && msg.user.isSelf && (
                      <>
                        <button onClick={handleEdit}>Edit</button>
                        <button onClick={handleDelete}>Delete</button>
                      </>
                    )}
                  </div>
                )}
              </MessageBubbleContainer>
            </Message>
          );
        })}
        <div ref={messagesEndRef} />
      </Messages>
      {replyingTo && (
        <div>
          <span>
            Replying to @{replyingTo.user.username}: {replyingTo.text}
          </span>
          <button onClick={() => setReplyingTo(null)}>X</button>
        </div>
      )}
      <ChatInputContainer>
        <ChatInput
          rows={Math.min(5, (input.match(/\n/g) || []).length + 2)}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && sendMessage()}
          placeholder={user ? "Type a message here... " : "Log in to chat"}
          disabled={!user}
        />
        <GifSearchIcon
          isGifMenuOpen={isGifMenuOpen}
          onClick={() => {
            setIsGifSearchOpen(!isGifSearchOpen);
            setIsGifMenuOpen(!isGifMenuOpen);
            if (isGifMenuOpen) {
              setIsGifListOpen(false);
              setGifSearchTerm("");
            }
          }}
        >
          {isGifMenuOpen ? "X" : "😊"}
        </GifSearchIcon>
      </ChatInputContainer>
      <GifSearchInput
        type="text"
        value={gifSearchTerm}
        onChange={(e) => setGifSearchTerm(e.target.value)}
        placeholder="Search for gifs..."
        isGifSearchOpen={isGifSearchOpen}
      />
      <GifSearchResults>
        {gifSearchResults.map((url, index) => (
          <GifImage
            key={index}
            src={url}
            onClick={() => {
              setInput(url);
              setGifSearchTerm("");
              setIsGifSearchOpen(false);
            }}
          />
        ))}
      </GifSearchResults>
      {!user && (
        <LoginPrompt>
          Please <Link to="/login">log in</Link> or{" "}
          <Link to="/signup">sign up</Link> to send messages.
        </LoginPrompt>
      )}
    </ChatContainer>
  );
};

export default Chat;
