import React, { useState, useEffect } from "react";
import { useMoviesContext } from "../hooks/useMoviesContext";
import UpcomingMovieCard from "../components/UpcomingMovieCard";
import styled from "styled-components";

const Schedule = () => {
  const { movies, dispatch } = useMoviesContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedHost, setSelectedHost] = useState("All Movies");

  const todayDateString = new Date().toISOString().split("T")[0];

  // Add today's date for comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const fetchTMDBData = async (title) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=8a08a585413d6340c209c3fca6b235d1&query=${encodeURIComponent(
          title
        )}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const movieData = data.results[0];
        return {
          title: movieData.title,
          backdrop: movieData.backdrop_path,
          poster_path: movieData.poster_path
            ? `https://image.tmdb.org/t/p/original${movieData.poster_path}`
            : "img/generic_vhs.jpg",
          release_date: movieData.release_date,
          genre: movieData.genre_ids,
          rating: movieData.vote_average,
          overview: movieData.overview,
          language: movieData.language,
          id: movieData.id,
          genre_ids: movieData.genre_ids,
        };
      }
    } catch (error) {
      console.error("Error fetching TMDB data:", error);
    }
    return {};
  };

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/movies`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const moviesFromDB = await response.json();

        // Enhance each movie with additional data from TMDb
        const moviesWithTMDB = await Promise.all(
          moviesFromDB.map(async (movie) => {
            const tmdbData = await fetchTMDBData(movie.title);

            return { ...movie, ...tmdbData };
          })
        );

        dispatch({ type: "SET_MOVIES", payload: moviesWithTMDB });
      } catch (error) {
        console.error("Error fetching movie(s):", error);
      }
    };
    fetchMovies();
  }, [dispatch]);

  // Filter movies based on search query, selected year, and whether the movie is upcoming
  const filteredMovies = movies
    .filter((movie) => {
      const movieWatchedDateString = new Date(movie.watched)
        .toISOString()
        .split("T")[0]; // Convert movie.watched to 'YYYY-MM-DD' string
      return (
        (selectedYear === "all" ||
          new Date(movie.watched).getFullYear() === selectedYear) &&
        movieWatchedDateString >= todayDateString // Compare as strings
      );
    })
    .filter((movie) => {
      const matchesTitle = movie.title.toLowerCase().includes(searchQuery);
      return selectedHost === "All Movies"
        ? matchesTitle
        : matchesTitle && movie.host === selectedHost;
    })
    .sort((a, b) => new Date(a.watched) - new Date(b.watched));

  return (
    <ScheduleContainer>
      <div>
        <Title>Schedule</Title>
        <Description>
          Check out our lineup of upcoming movie watchalongs! We've picked a
          variety of films to suit all tastes, combining classics with new hits.
          It's a great chance to meet fellow movie lovers and dive deep into
          discussions about each film. Perfect for both avid cinephiles and
          casual viewers looking for a cozy movie night. Join us for these
          watchalongs and let's watch some so-bad-they're-good movies together!
        </Description>
        <MovieGrid>
          {filteredMovies?.length > 0 ? (
            filteredMovies.map((movie) => (
              <UpcomingMovieCard key={movie._id} movie={movie} />
            ))
          ) : (
            <NoMoviesFound>Loading...</NoMoviesFound>
          )}
        </MovieGrid>
      </div>
    </ScheduleContainer>
  );
};

export default Schedule;

const ScheduleContainer = styled.div`
  margin: 0 auto;
  width: 60%;
`;

const Title = styled.h2`
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
`;

const MovieGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const NoMoviesFound = styled.p`
  text-align: center;
  font-size: 1.5rem;
  color: #d71f20;
`;
